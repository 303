import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import LandingPage from './Components/LandingPage';
import Body from './Components/Body';
import Main from './Components/Products/HandBuk/Main';
import Services from '../src/Pages/Services';
import './App.css';
import ComingSoon from './Components/ComingSoon';
import AboutUS from './Pages/AboutUSPage/AboutUS';
import BookDemo from './Pages/DemoPage/BookDemo';
import ContactUS from './Pages/ConatctUsPage/ContactUS';

const App = () => {
  const appRouter = createBrowserRouter([
    {
      path: '/',
      element: <LandingPage />,
      children: [
        {
          path: '/',
          element: <Body />
        },
        {
          path: '/contactus',
          element: <ContactUS />
        },
        {
          path: '/book-a-demo',
          element: <BookDemo />,
        },
        {
          path: '/about-us',
          element:<AboutUS />
        },
        {
          path: '/services',
          element:<Services />
        },
        {
          path: '/handbuk',
          element: <Main />,
        },
        {
          path: '/careers',
          element:<ComingSoon />
        },
        {
          path: '/resources',
          element:<ComingSoon />
        },
        {
          path: '/chatbot',
          element:<ComingSoon />
        },
      ]
    }
  ])
  return (
    <>
      <RouterProvider router={appRouter} />
    </>
  )
}

export default App;
