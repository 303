import React from 'react'

const Footer = () => {
  return (
    <div className="bg-black text-white text-center py-1 md:py-2">
      <div className="md:mb-4">
        <p className="hidden md:inline-block">
          Engage and connect with your employees no matter where they are
        </p>
      </div>
      <div className="md:mb-2">
        <p>
          Copyright © 1999-2024 Arka Tech Solutions, LLC. All rights reserved.{" "}
          <a
            href="https://arkatechsol.com/privacy"
            className="underline cursor-pointer"
            target='_blank'
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
}

export default Footer