export const addUserDetailsAPI = "https://arkawebserver-ejc5ehcra0gkghcm.westus-01.azurewebsites.net/addUserDetails";
export const addDemoDetailsAPI =
"https://arkawebserver-ejc5ehcra0gkghcm.westus-01.azurewebsites.net/addDemoDetails";
export const email_regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const mobile_regex =
  /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;
export const appStoreLink = "https://apps.apple.com/us/app/handbuk/id6557054526";
export const playStoreLink = "https://play.google.com/store/apps/details?id=com.ArkaTechSol.handbuk";
export const DownArrowIcon = (
  <svg
    class="h-5 w-5 text-black"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    
    <polyline points="6 9 12 15 18 9" />
  </svg>
);
export const UpArrowIcon = (
  <svg
    class="h-5 w-5 text-black"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    
    <polyline points="18 15 12 9 6 15" />
  </svg>
);
export const twitterIcon = (
  <svg
    class="h-6 md:h-8 w-6 md:w-8 text-sky-500"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    stroke-width="2"
    stroke="currentColor"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <path d="M22 4.01c-1 .49-1.98.689-3 .99-1.121-1.265-2.783-1.335-4.38-.737S11.977 6.323 12 8v1c-3.245.083-6.135-1.395-8-4 0 0-4.182 7.433 4 11-1.872 1.247-3.739 2.088-6 2 3.308 1.803 6.913 2.423 10.034 1.517 3.58-1.04 6.522-3.723 7.651-7.742a13.84 13.84 0 0 0 .497 -3.753C20.18 7.773 21.692 5.25 22 4.009z" />
  </svg>
);
export const linkedinIcon = (
  <svg
    class="h-6 md:h-8 w-6 md:w-8 text-sky-500"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />
    <rect x="2" y="9" width="4" height="12" /> <circle cx="4" cy="4" r="2" />
  </svg>
);
export const instaIcon = (
  <svg
    class="h-6 md:h-8 w-6 md:w-8 text-sky-500"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />
    <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
    <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" />
  </svg>
);
export const facebookIcon = (
  <svg
    class="h-6 md:h-8 w-6 md:w-8 text-sky-500"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
  </svg>
);

export const homeIcon = (
  <svg
    class="h-8 w-8 text-black"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    stroke-width="2"
    stroke="currentColor"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    
    <path stroke="none" d="M0 0h24v24H0z" />
    <polyline points="5 12 3 12 12 3 21 12 19 12" />
    <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
    <rect x="10" y="12" width="4" height="4" />
  </svg>
);

export const CloseIcon = (
  <svg
    class="h-8 w-8 text-black"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
);

export const closeToLeftIcon = (
  <svg
    class="h-8 w-8 text-customGreen"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    stroke-width="2"
    stroke="currentColor"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    
    <path stroke="none" d="M0 0h24v24H0z" />
    <line x1="20" y1="6" x2="13" y2="6" />
    <line x1="20" y1="12" x2="11" y2="12" />
    <line x1="20" y1="18" x2="13" y2="18" /> <path d="M8 8l-4 4l4 4" />
  </svg>
);

export const closeToRightIcon = (
  <svg
    class="h-8 w-8 text-customGreen"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    stroke-width="2"
    stroke="currentColor"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    
    <path stroke="none" d="M0 0h24v24H0z" />
    <line x1="20" y1="6" x2="9" y2="6" />
    <line x1="20" y1="12" x2="13" y2="12" />
    <line x1="20" y1="18" x2="9" y2="18" /> <path d="M4 8l4 4l-4 4" />
  </svg>
);

export const logo = require("../Utils/Assets/Logo-removebg.png");
export const profileImg = require("../Utils/Assets/profile.jpeg");
export const handbukLogo = require("../Utils/Assets/HandBukLogo.png");
export const chatLogo = require("../Utils/Assets/chatLogo.png");
export const ourMission = require("../Utils/Assets/ourMission.png");
export const ourVision = require("../Utils/Assets/ourVission.png");
export const ourValues = require("../Utils/Assets/OurValues.jpg");
export const mission = require("../Utils/Assets/Mission.jpg");
export const vision = require("../Utils/Assets/Vision.jpg");
export const values = require("../Utils/Assets/OurValues.jpg");
export const Slide2 = require("../Utils/Assets/Slide2.jpg");
export const Slide3 = require("../Utils/Assets/Slide3.jpg");
export const Slide4 = require("../Utils/Assets/Slide4.jpg");
export const Slide5 = require("../Utils/Assets/Slide5.jpg");
export const Slide6 = require("../Utils/Assets/Slide6.jpg");
export const Slide1 = require("../Utils/Assets/Slide1.jpg");
export const Slide7 = require("../Utils/Assets/Slide7.jpg");
export const WD = require("../Utils/Assets/WD.jpeg");
export const MLD = require("../Utils/Assets/MLD.jpeg");
export const CSD = require("../Utils/Assets/CSD.jpeg");
export const DV = require("../Utils/Assets/DV.jpeg");
export const BI = require("../Utils/Assets/BI.jpeg");
export const MAD = require("../Utils/Assets/MAD.jpeg");
export const bgImg = require("../Utils/Assets/bg-img.png");
export const demoImg = require("../Utils/Assets/planning.png");
export const appLogo = require("../Utils/Assets/AppLogo.png");
export const comingSoonImg = require("../Utils/Assets/comingSoon.png");

export const hydMapsLocation = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1611655.7587456405!2d77.22601997559202!3d17.408867320098814!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99daeaebd2c7%3A0xae93b78392bafbc2!2sHyderabad%2C%20Telangana%2C%20India!5e1!3m2!1sen!2sus!4v1725495399087!5m2!1sen!2sus" 

export const seattleLocation = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4466.980794651982!2d-122.15424039999999!3d47.38672899999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54905e606bf6788b%3A0x87910d3e215a635f!2s24003%20140th%20Ave%20SE%2C%20Kent%2C%20WA%2098042!5e1!3m2!1sen!2sus!4v1725495234114!5m2!1sen!2sus"

export const bangloreLocation = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d823019.7168367784!2d77.30130670586178!3d12.953790249231618!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1670c9b44e6d%3A0xf8dfc3e8517e4fe0!2sBengaluru%2C%20Karnataka%2C%20India!5e1!3m2!1sen!2sus!4v1725495575452!5m2!1sen!2sus"
