import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { chatLogo, CloseIcon, DownArrowIcon, handbukLogo, logo, UpArrowIcon } from '../Utils/constants';

const Header = () => {
  const navigate = useNavigate();
  const [menuItems, setMenuItems] = useState(false);
  const [showDropDown, setShowDropDwon] = useState(false);
  const products = [
    {name: "HandBuk", logo: handbukLogo, description: "Empowering Business through StreamLIned Financial Management", path: "/handbuk"},
    {name: "Chat Bot", logo: chatLogo, description: "Coming Soon", path: "/chatbot"},
  ]
  const handleMenuItems = (e) => {
    e.preventDefault();
    setMenuItems(!menuItems);
  };
  const toggleDropdown = (e) => {
    e.stopPropagation();
    setShowDropDwon(!showDropDown)
  }
  useEffect(() => {
    const onClickHandle = function() {
      setShowDropDwon(false);
    }
    document.body.addEventListener('click', onClickHandle);
    return () => {
      document.body.removeEventListener('click', onClickHandle);
    }
  },[]);
  return (
    <div className="flex items-center justify-between px-2 md:px-10 lg:px-4 w-full shadow-lg">
      <div
        className="m-2 p-2 flex items-center"
        onClick={() => {
          navigate("/");
          if (menuItems) {
            setMenuItems(!menuItems);
          }
        }}
      >
        <img
          src={logo}
          alt="logo"
          className="w-20 md:w-24 cursor-pointer rounded-lg"
        />
      </div>
      <div className="hidden md:inline-block p-2 m-2 items-center justify-between space-x-6 text-black">
        <button
          className=" font-semibold text-xl"
          onClick={() => navigate("/")}
        >
          Home
        </button>
        <button
          className=" font-semibold text-xl"
          onClick={() => navigate("/about-us")}
        >
          About us
        </button>
        <button className="relative" onClick={toggleDropdown}>
          <span className="flex items-center font-semibold text-xl">
            Products
            <span className="pt-1">
              {showDropDown ? UpArrowIcon : DownArrowIcon}
            </span>
          </span>
          {showDropDown && (
            <div className="absolute rounded-xl shadow-lg left-0 z-10 mt-2 w-96 text-left bg-white text-black">
              <ul className="py-2">
                {products.map((product) => (
                  <div
                    key={product.name}
                    className="flex items-center p-4 hover:bg-slate-100 space-x-6"
                    onClick={() => {
                      navigate(product.path);
                    }}
                  >
                    <img
                      src={product.logo}
                      alt="handbuk-logo"
                      className="w-10"
                    />
                    <div>
                      <li className="h-7 hover:bg-slate-100">{product.name}</li>
                      <p className="text-sm text-slate-500">
                        {product.description}
                      </p>
                    </div>
                  </div>
                ))}
              </ul>
            </div>
          )}
        </button>
        <button
          className=" font-semibold text-xl"
          onClick={() => navigate("/services")}
        >
          Services
        </button>
        <button
          className=" font-semibold text-xl"
          onClick={() => navigate("/careers")}
        >
          Careers
        </button>
        {/* <button
          className=" font-semibold text-xl"
          onClick={() => navigate("/resources")}
        >
          Resources
        </button> */}
        <button
          className=" font-semibold text-xl"
          onClick={() => navigate("/contactus")}
        >
          Contact us
        </button>
        &nbsp;
        <button
          className="font-semibold text-xl px-6 bg-yellow-600 rounded-lg text-white h-10 hover:bg-gray-800"
          onClick={() => navigate("/book-a-demo")}
        >
          Book-A-Demo
        </button>
      </div>
      <div className="inline-block md:hidden">
        <button
          className="relative text-3xl items-center p-2 m-4 "
          onClick={handleMenuItems}
        >
          {!menuItems ? <span>&#9776;</span> : CloseIcon}
        </button>
      </div>
      {menuItems && (
        <div className="inline-block md:hidden fixed z-50 bg-white mt-[60%] ml-[60%] px-2 w-[30%] text-left shadow-lg rounded-lg">
          <ul className="space-y-2 py-2 text-customGreen cursor-pointer">
            <li
              onClick={() => {
                navigate("/about-us");
                setMenuItems(!menuItems);
              }}
            >
              About us
            </li>
            <li
              onClick={() => {
                navigate("/handbuk");
                setMenuItems(!menuItems);
              }}
            >
              HandBuk App
            </li>
            <li
              onClick={() => {
                navigate("/services");
                setMenuItems(!menuItems);
              }}
            >
              Services
            </li>
            <li
              onClick={() => {
                navigate("/careers");
                setMenuItems(!menuItems);
              }}
            >
              Careers
            </li>
            <li
              onClick={() => {
                navigate("/contactus");
                setMenuItems(!menuItems);
              }}
            >
              Contact us
            </li>
            <li
              onClick={() => {
                navigate("/book-a-demo");
                setMenuItems(!menuItems);
              }}
            ></li>
            <li
              onClick={() => {
                navigate("/book-a-demo");
                setMenuItems(!menuItems);
              }}
            >
              Demo
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Header;